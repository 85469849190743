export interface IAnalytics {
    onFeaturedItemClick: (title: string) => void;
    onFeaturedItemShown: (title: string) => void;
}

export enum AnalyticsCategories {
    FeaturedContent = 'Featured Content',
    AdvancedSearch = 'Advanced Search',

}

export class GoogleAnalytics implements IAnalytics {

    private _dataLayer: any[];

    constructor() {
        this._dataLayer = (window as any).dataLayer || [];
    }

    private pushEvent = (eventCategory: AnalyticsCategories, eventAction: string, eventLabel: string, eventValue = undefined) => {
        const tag = {
            event: 'GAEvent',
            eventCategory,
            eventAction,
            eventLabel,
            eventValue,
        };
        console.log(`Sending tag to data layer: ${JSON.stringify(tag)}`)
        this._dataLayer.push(tag);
    }

    onFeaturedItemClick = (title: string) => {
        this.pushEvent(AnalyticsCategories.FeaturedContent, 'FeaturedContentClicked', title.toLowerCase())
    }

    onFeaturedItemShown = (title: string) => {
        this.pushEvent(AnalyticsCategories.FeaturedContent, 'FeaturedContentShown', title.toLowerCase())
    }

    
}
