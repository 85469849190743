import { SvgSprite } from './modules/svg-loader';
import { SurveyQuestion } from './modules/esrm-question';
import { initFeaturedContentBlocks } from './modules/featured-content';

(() => {
    // SVG Sprite Inject
    new SvgSprite('/Static/images/asis-sprite.svg');

    initFeaturedContentBlocks();

    let surveyQuestions = new SurveyQuestion();


})();

