import { nodeListToArray } from '../helpers/nodeListToArray';

let surveyQuestions = nodeListToArray(document.querySelectorAll('[data-esrm-question]'));

export class SurveyQuestion {
    constructor() {

        surveyQuestions.forEach(question => {
            let ratings = nodeListToArray(question.querySelectorAll('input'));
            ratings.forEach(input => {
                input.addEventListener('click', this.updateCurrentScore);
            });
        });
    }

    updateCurrentScore(e) {
        let input = e.target;
        var score = 0;
  
        var question = input.closest('[data-esrm-question]');
        var groupSelectedRadios = nodeListToArray(question.querySelectorAll('input[type="radio"]:checked'));
        var groupSelectedValues = [];

        groupSelectedRadios.forEach((selection, index) => {
            if (index < 2) { // only people and process
                groupSelectedValues[index] = parseInt(selection.dataset.score);
            }
        });

        groupSelectedValues.forEach(value => {
            score += value;
        });

        question.querySelector('[data-average-score]').innerHTML = Math.round(score / groupSelectedValues.length);

    }
}
