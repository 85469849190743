import { nodeListToArray } from '../helpers/nodeListToArray';
import { debounce } from 'lodash';
import { enableTabbableChildren } from '../helpers/enableTabbableChildren';
import { disableTabbableChildren } from '../helpers/disableTabbableChildren';
import '../helpers/closestPolyfill';
import { GoogleAnalytics } from './google-analytics';


export function initFeaturedContentBlocks() {

    let analytics = new GoogleAnalytics();

    let containers = document.querySelectorAll('[data-featured-content-block]');
    for (let i = 0; i < containers.length; i++) {
        setupBlock(containers[i]);
    }

    function setupBlock(container) {
        if (!container) return;

        let featuredItemLinks = container.querySelectorAll('[data-gtm-featured]');
        for (let i = 0; i < featuredItemLinks.length; i++) {
            let title = featuredItemLinks[i].getAttribute('data-gtm-featured');

            featuredItemLinks[i].addEventListener("click", () => {
                analytics.onFeaturedItemClick(title);
            })

            analytics.onFeaturedItemShown(title);
        }

    }
}
